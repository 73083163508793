.alarm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 10px;
}

.datetime {
  color: #201c1c;
  background: #ededf3;
  font-family: "Segoe UI", sans-serif;
  width: 340px;
  padding: 15px 10px;
  transition: 0.5s;
  transition-property: background, box-shadow;
}

.date {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 3px;
}

.time {
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time span:not(:last-child) {
  position: relative;
  max-width: 0 6px;
  font-weight: 600;
  text-align: center;
}

.time span:last-child {
  background: #ececf1;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
  padding: 4px 5px;
  border-radius: 3px;
}

hr {
  border: 1px solid #116d6e;
  margin: 20px auto;
}

.setAlarm {
  display: flex;
  justify-content: space-around;
}

.column select {
  background: #ececf1;
  color: #1a1616;
  text-align: center;
  padding: 8px 4px;
  border: 1px solid #116d6e;
  margin: 6px;
}

#btn-setAlarm,
.btn-delete,
#stopAlarm {
  padding: 5px;
  color: black;
  background: #00008B;
  border: 2px solid black;
}

#stopAlarm {
  visibility: hidden;
  width: 100px;
  margin: 0 34%;
}

.btn-delete {
  margin-left: 50px;
}

#btn-setAlarm:hover,
.btn-delete:hover,
#stopAlarm:hover {
  cursor: pointer;
  background: #00008B;
  box-shadow: 0 0 30px #00008B;
}

.alarmList {
  margin: 14px auto;
}

.table-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  padding: 10px;
  background: #ededf3;
  border-radius: 5px;
}

.table-column {
  flex: 1;
}

.alarmLog {
  font-size: 30px;
}

.alarmName {
  text-align: left;
  margin-left: 40px;
}



  


 