.video-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .video-item {
    margin: 10px;
    border:  2px solid #116d6e;
  }
  
  .see-all {
    cursor: pointer;
    text-decoration: underline;
    padding-top: 10%;
    color: blue;
  }
  
  .video-content{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px ;
  padding: 10px;
  }
  h2{
    padding: 40px;
  }